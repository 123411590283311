import { useContext, useEffect, useState } from "react";
import Table from "./Table";
import axios from "axios";
import { makeNumberCurrency } from "../helpers";
import { FlashContext } from "../flash-context";
import { Button } from "../common/Button";

export default function CostTable(props) {
    const [materialCostsDict, setMaterialCostsDict] = useState({});
    const [showItems, setShowItems] = useState([]);
    const [itemDescs, setItemDescs] = useState([]);
    const { flash, setFlash } = useContext(FlashContext);
    const [editedItem, setEditedItem] = useState(null);
    const [itemDescription, setItemDescription] = useState(null);
    const [descDropdownOptions, setDescDropdownOptions] = useState(null);

    /*
    Before rendering the table, these variables are generated:
    - itemDescs = item descriptions relevant to this job (machine-material combos, printer & hourly charge, and 'other')
    - materialCostsDict = dictionary mapping description name to price
    - descDropdownOptions = HTML select options for the descriptions dropdown
    */
    useEffect(() => {
        axios.get('item_descs/default').then(function (response) {
            let descs = [];
            let costs = {};
    
            props.machineMaterialCombos.map((m, index) => {
                if (m.machine_id != 0 && m.material_id != 0) {
                    let name =`${m.machine_name} | ${m.material_name}` 
                    descs.push({'desc_id': false, 'name': name, 'machine_id': m.machine_id, 'material_id': m.material_id});
                    costs[name] = m.material_cost
                }
            })
    
            props.machines.forEach((m) => {
                descs.push({"desc_id": false, "name": "Printer Base Charge", "machine_id": -1, "material_id": -1 });
                costs["Printer Base Charge"] = m.base_charge
                descs.push({"desc_id": false, "name": "Hourly Charge", "machine_id": -1, "material_id": -1 });
                costs["Hourly Charge"] = m.hourly_cost
            })

            response.data.map((d) => {
                descs.push({'desc_id': d.id, 'name': d.name, 'machine_id': -1, 'material_id': -1});
                costs[d.name] = 0;
            })

            setItemDescs(descs);
            setMaterialCostsDict(costs);

            const options = [<option value={false} key={-1} hidden label="Select"/>, ...descs.map((desc, index) => <option value={index} key={index} label={desc.name}/>)]
            setDescDropdownOptions(options);
        }).catch(function (error) {
            console.error(error);
        });
    }, [])

    /*
    After the dropdown options are created, the rows in the cost table (showItems state var) are created
    The rows in the cost table are also re-rendered every time the job's information is updated
    ie. When props.getJob() is called after an item is added/updated or deleted (see updateOrCreateItem and deleteItem)
    */
    useEffect(() => {
        const rows = createTableRows();
        setShowItems(rows);
    }, [descDropdownOptions, props.job])

    /*
    Whenever an item is edited or added (when "Add Item" or "Edit" button is pressed), editedItem variable is set (see handleAddOrEdit)
    After that, the rows in the cost table are updated to include the edited row (insertEditableRow)
    */
    useEffect(() => {
        if (editedItem !== null) {
            insertEditableRow();
        } else {
            const rows = createTableRows();
            setShowItems(rows);
        }
    }, [editedItem]);

    const createTableRows = () => {
        return props.items.map((item, index) => {
            return {
                "description": <span>{item.item_desc || "None"}</span>,
                "notes": <span>{item.notes || ''}</span>,
                "quantity": <span>{item.quantity || 0}</span>,
                "price": <span>${makeNumberCurrency(item.price) || 0}</span>,
                "subtotal": <span>${makeNumberCurrency(item.subtotal) || 0}</span>,
                "action": defaultRowButtons(item, index)
            }
        })
    }

    const defaultRowButtons = (l, index) => {
        return <div>
            <Button className="btn btn-primary" label="Edit" dataCy="admin-cost-edit-button" onClick={() => handleAddOrEdit(index)}/>
            <Button className="btn btn-primary" label="Delete" dataCy="admin-cost-delete-button" onClick={() => deleteItem(l.id)} /> 
        </div>
    }
    
    /*
    If the current value of editedItem is not null, then another item is currently being edited
    Else, if the provided index param is null, user wants to add a new item
    Otherwise, user wants to edit an item that is already amongst the existing items for the job
    */
    const handleAddOrEdit = (index = null) => {
        if (editedItem !== null) {
            alert("Please save the current item before editing another one.");
        } else {
            const item = index === null ?
                {index: null, id: null, item_desc: "", notes: "", quantity: 1, price: 0, subtotal: 0} :
                {index: index, ...props.items[index]};
            if (index !== null) {setItemDescription(itemDescs.find((i) => i.name === item.item_desc))};
            setEditedItem(item);
        }
    }

    /*
    After the editedItem variable is set, this function inserts a row with input fields into the table
    If the index of editedItem is null, user wants to create a new item and the row is added to the end
    Otherwise, user wants to edit an existing item and that row is replaced by an editable row with populated values
    */
    const insertEditableRow = () => {
        let show_items = createTableRows();
        const desc_option_value = itemDescs.findIndex(i => i.name === editedItem.item_desc);
        const new_row = {
            "description": itemDescSelector(desc_option_value),
            "notes": <input onChange={e => handleEditedItemUpdate({"notes": e.target.value})} type="text" className="form-control" id="itemNotes" aria-describedby="itemNotes" value={editedItem.notes}/>,
            "quantity": <input onChange={e => handleEditedItemUpdate({"quantity": e.target.value})} type="number" className="form-control" id="quantity" aria-describedby="quantity" required min={1} value={editedItem.quantity}/>,
            "price": <input onChange={e => handleEditedItemUpdate({"price": e.target.value})} type="number" className="form-control" id="price" aria-describedby="price" required min={0} value={editedItem.price}/>,
            "subtotal": <input type="text" className="form-control" id="subtotal" aria-describedby="subtotal" required min={0} value={editedItem.subtotal || 0} disabled readOnly/>,
            "action": editableRowButtons()
        }; 
        if (editedItem.index !== null) {
            show_items[editedItem.index] = new_row;
        } else {
            show_items.push(new_row)
        }

        setShowItems(show_items);
    }

    /*
    Returns an HTML select tag with the relevant options for the job
    If user is editing an existing item, the item's description must be pre-selected (desc_option_value)
    */
    const itemDescSelector = (desc_option_value) => {
        return <select onChange={e => handleItemDescChange(e)} className="form-select" aria-label="line item" id="line item" value={desc_option_value}>
            {descDropdownOptions}
        </select>;
    }

    const editableRowButtons = () => {
        return <div>
            <Button className="btn btn-primary" label="Save" dataCy="admin-cost-save-button" onClick={() => updateOrCreateItem()} />
            <Button className="btn btn-primary" label="Cancel" onClick={() => setEditedItem(null)} />
        </div>
    }

    /*
    Handler for the item description dropdown (see itemDescSelector)
    When a description name is chosen in the dropdown, the respective description from itemDescs is saved in state (itemDescription)
    Because the price must dynamically change based on the item description, we update price in addition to item_desc for editedItem
    */
    const handleItemDescChange = (e) => {
        const chosen_description = itemDescs[e.target.value];
        setItemDescription(chosen_description);
        handleEditedItemUpdate({"price": materialCostsDict[chosen_description.name], "item_desc": chosen_description.name});
    }

    /*
    Takes a dictionary as input and updates the respective properties of the editedItem
    */
    const handleEditedItemUpdate = (updates) => {
        let newEditedItem = {...editedItem};
        for (const [key, value] of Object.entries(updates)) {
            newEditedItem[key] = value;
            if (key === "quantity" || key === "price") {
                newEditedItem.subtotal = Math.round(newEditedItem.price * newEditedItem.quantity * 100) / 100;
            }
        }
        setEditedItem(newEditedItem);
    }

    const updateOrCreateItem = () => {
        if (window.confirm("Are you sure you want to update?")) {
            let method = editedItem.id ? "PUT" : "POST";
            let url = editedItem.id ? `line_items/${editedItem.id}` : `line_items/`;
            const form = new FormData();
            form.append("bs_job_id", props.job.id);
            form.append("item_type_id", props.typeId);
            form.append("notes", editedItem.notes);
            form.append("quantity", editedItem.quantity);
            form.append("price", editedItem.price);
            form.append("subtotal", editedItem.subtotal);

            form.append("item_desc_id", itemDescription.desc_id);
            form.append("item_desc_name", itemDescription.name);
            form.append("item_desc_machine_id", itemDescription.machine_id);
            form.append("item_desc_material_id", itemDescription.material_id);

            const options = {
                method: method,
                url: url,
                headers: {
                    "Content-Type": "multipart/form-data",
                    "enctype": "multipart/form-data"
                },
                data: form
            };

            axios.request(options).then(function (response) {
                if (response.data.error) {
                    console.log(response.data);
                } else {
                    props.getJob();
                    setEditedItem(null);
                    setFlash({ type: "success", message: "Updated Item!" });
                }
            }).catch(function (error) {
                console.log(error);
                setFlash({ type: "danger", message: "Description needed to save cost item!" });
            });
        }
    }

    const deleteItem = (id) => {
        if (window.confirm("Are you sure you want to delete this item?")) {
            const options = {
                method: "DELETE",
                url: `line_items/${id}`,
                headers: {
                    "Content-Type": "multipart/form-data",
                    "enctype": "multipart/form-data"
                }
            };

            axios.request(options).then(function (response) {
                if (response.data.error) {
                    console.log(response.data);
                } else {
                    props.getJob();
                    setEditedItem(null);
                    setFlash({ type: "success", message: "Deleted Item!" });
                }
            }).catch(function (error) {
                console.error(error);
            });
        }
    }

    return <>
        <Table colName={["Description", "Notes", "Quantity", "Price", "Subtotal", "Action"]} data={showItems} />
        <Button className="btn btn-primary btn-margin-fix admin-addon-btn" dataCy="admin-cost-add-item" id="line_items" onClick={() => handleAddOrEdit()} label="Add Item"/>
    </>
}